<!-- Finder de Expedientes Presupuestos -->

<template>
 <div class="exp_M_presup conflex contenedor pt-3"
   if="schema.ctrls.id.value !== ''"
       style="justify-content:center;background-color: #eee;">
  <v-sheet :elevation="4" min-height="500px">
        <!-- Grid -->
    <base_Fgrid
         v-model="selectedItems"
         :padre="stName"
         :Entorno="Entorno"
         :value="selectedItems"
          dense
          min-width='800px'
          @onEvent="event_Grid"
          show-select>

          <!-- Slot botonera Top -->
          <!-- <template v-slot:top_btns="{ Sitem }">  -->
      <template v-slot:top_btns="{}"> 
         <div style="display:flex;justify-content:space-between;padding:10px 0 0 10px">        

  <!-- BOTONERA FACTURAS grupo -->
            <div v-if="app==2 && !cerrada" style="width: 330px;display:flex;justify-content:space-between;margin-right:10px">

              <!-- Botonera APD -->
              <div  style="width: 250px;display:flex;justify-content:space-between;margin-right:10px">

                    <v-btn depressed fab x-small color="blue-grey lighten-1"
                      @click="fusion('C',0,false,1)">
                      <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                      <sub style="color:orange;font-size:1rem;">C</sub>
                      <sub style="color:white;font-size:0.8rem;">1</sub>
                    </v-btn>
                    <v-btn depressed fab x-small color="blue-grey lighten-1"
                      @click="fusion('C',0,false,2)">
                      <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                      <sub style="color:orange;font-size:1rem;">C</sub>
                      <sub style="color:white;font-size:0.8rem;">2</sub>
                    </v-btn>
                    <v-btn depressed fab x-small color="blue-grey lighten-1"
                      @click="fusion('C',0,false)">
                      <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                      <sub style="color:orange;font-size:1rem;">C</sub>
                    </v-btn>
                      <v-btn depressed fab x-small color="blue-grey lighten-1"
                       @click="fusion('C',1,false)">
                      <div style="color:white;font-weight:bold;font-size:1.2rem"> + </div>
                      <sub style="color:red;font-size:1rem;">S</sub>
                    </v-btn>
             
                    
              <div style="width: 70px;display:flex;justify-content:space-between;margin-right:10px">
                    <v-btn fab  depressed x-small color="blue-grey lighten-1"
                       @click="fusion('C',0,true)">
                      <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                      <sub style="color:orange;font-size:1rem;"> C</sub>
                    </v-btn>
  
                     <v-btn depressed fab x-small color="blue-grey lighten-1"
                      @click="fusion('C',1,true)">
                      <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                      <sub style="color:red;font-size:1rem;"> S</sub>
                    </v-btn>
 
              </div>
            </div>
        </div>  

     
                <!-- Bontonera GESTION -->
        <div v-if="app==1 && !cerrada" style="width: 275px;display:flex;justify-content:space-between;margin-right:10px">
              <div style="width: 110px;display:flex;justify-content:space-between;margin-right:10px">


                  <v-btn fab depressed x-small color="blue-grey lighten-1"
                       @click="fusion('V',0,false)">
                       <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                      <sub style="color:green;font-size:1rem;"> V</sub>
                    </v-btn>

                    <v-btn depressed fab x-small color="blue-grey lighten-1"
                      @click="fusion('C',0,false)">
                      <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                      <sub style="color:orange;font-size:1rem;">C</sub>
                    </v-btn>

                      <v-btn depressed fab x-small color="blue-grey lighten-1"
                       @click="fusion('C',1,false)">
                      <div style="color:white;font-weight:bold;font-size:1.2rem"> + </div>
                      <sub style="color:red;font-size:1rem;">S</sub>
                    </v-btn>
                    
              </div>
                    
              <div style="width: 110px;display:flex;justify-content:space-between;margin-right:10px">
                    <v-btn fab  depressed x-small color="blue-grey lighten-1"
                       @click="fusion('C',0,true)">
                      <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                      <sub style="color:orange;font-size:1rem;"> C</sub>
                    </v-btn>
                   <v-btn fab depressed x-small color="blue-grey lighten-1"
                       @click="fusion('V',0,true)">
                      <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                      <sub style="color:green;font-size:1rem;"> V</sub>
                    </v-btn>
                     <v-btn depressed fab x-small color="blue-grey lighten-1"
                      @click="fusion('C',1,true)">
                      <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                      <sub style="color:red;font-size:1rem;"> S</sub>
                    </v-btn>
             </div>

        </div>

        <!-- boton refresco -->
        <div>
           Refrescar datos
           <v-btn
                icon
                color="green"
                @click="refresco()"
              >
              
                <v-icon>mdi-cached</v-icon>
               
           </v-btn>        
        </div> 
        <!-- botonera cierre -->
        <div style="width: 230px;display:flex;justify-content:space-between;margin-right:10px">
            <div>
              <v-chip  small  ma-2
              
                      :color="miStPadre.record.chkpresapd=='1'?'#8B0000':'#D3D3D3'"
                       text-color='white'>
                  <v-icon small left> mdi-lock </v-icon>
                   APD 
           
               </v-chip>
            </div>
            <div>
              <v-chip  small  ma-2
                      :color="miStPadre.record.chkconf24=='1'?'#8B0000':'#D3D3D3'"
                       text-color='white'>
                  <v-icon small left> mdi-lock </v-icon>
                   CTD 
           
               </v-chip>
            </div>
            <div>
              <v-chip  small  ma-2
                      :color="miStPadre.record.chkconfadmon=='1'?'#8B0000':'#D3D3D3'"
                       text-color='white'>
                  <v-icon small left> mdi-lock </v-icon>
                   ADM 
              </v-chip>
            </div>
        </div>


            <!-- fin grupo botoneras factura -->
        </div>
        </template>

          <template v-slot:bloqueada={Sitem}> 
            <v-icon size="medium" :color="Number(Sitem.bloqueada)==1 ?'red':'green'">  {{Sitem.bloqueada | bloqueada }} </v-icon>
            <v-icon size="medium" :color="Number(Sitem.aceptada)==1 ?'green':'red'">  {{Sitem.aceptada | aceptada }} </v-icon>
          </template>

          <template v-slot:suplido={Sitem}>
           <div style="color:red;font-weight:bold">
             {{Sitem.suplido=='1'?'SUP':''}}
            </div>
          </template>

          <!-- <template v-slot:pagada={Sitem}>
           <div style="color:green;font-weight:bold">
             {{Sitem.pagada!=''?'X':''}}             
            </div>
          </template> -->

          <!-- <template v-slot:bloqueada={Sitem}> 
          <v-icon size="medium" :color="Number(Sitem.bloqueada)==1 ?'green':'red'">  {{Sitem.bloqueada | bloqueada }} </v-icon>
          </template>
          <template v-slot:aceptada={Sitem}> 
          <v-icon size="medium" :color="Number(Sitem.aceptada)==1 ?'green':'red'">  {{Sitem.aceptada | aceptada }} </v-icon>
          </template>
          <template v-slot:suplido={Sitem}>
           <div style="color:red">
             {{Sitem.suplido=='1'?'SUP':''}}
            </div>
          </template> -->
          <!-- Slot campo acciones del Grid -->

          <template v-slot:acciones="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>   
            
        </base_Fgrid>
      </v-sheet>
  

      <!-- Mto como ventana modal -->      
      <v-dialog
        v-model="modal"
        persistent>
          <div class="centrado">             
            <component                 
                :is="component_M"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                :padre="stName"
                :datos_complementarios="datos_nuevo_presupuesto"                
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>      

  </div>
</template>
      <!-- <v-chip @click="blinds">
          <v-icon left>
            mdi-blinds
          </v-icon>
          Close blinds
        </v-chip> 
             <v-chip
        class="ma-2"
        color="secondary"
      >
        Secondary
      </v-chip>-->

<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const exp_pres_M = () => plugs.groute("exp_pres_M.vue", "comp");  

  export default {
    mixins: [mixinFinder],
    components: { base_Fgrid, btracombi, exp_pres_M },
    props: {
      padre: { type:String, default: ''},
      id: { type:[Number, String], default: 0},
      componenteTipo: { type:String, default:'FM' },   
      disparoFinder: { type:Boolean, default:false },   
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,             
        api:'exp_pres_F',
        stName:'stFexp_pres_F',       
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},
        disparo:false,  
        
        headers:[],
        
        // datos que se pasan para fusion / nuevo presupuesto
        selectedItems:[],
        datos_nuevo_presupuesto:{}
      }
    },
    // Para renderizado de iconos
   filters: {
    bloqueada(i) { 
     if (i==1) return "mdi-lock";
     if (i==0) return "mdi-lock-open";
     return "mdi-pencil";

   },
     aceptada(i){
      if (i==1) return "mdi-check-bold";
      if (i==2) return "mdi-close";
      return ""
   },
   },
    methods: {
 
       get_records_fin(){
         console.log("selecteditems:  ",this.selectedItems)
         // controla el value del data-grid, ya que almacena los items seleccionados 
         // A su vez selectedItems se carga por v-model del data-table
         // Se inicializa cada vez que se recarga porque el componente data-table mantiene lo 
         // seleccionado 
         this.selectedItems=[];
  
       },
       ini_data() {      
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local        
        this.schema = this.$store.state[this.stName].schema;

        // le paso el índice del record detalle que coge del Mto
        this.$store.commit('data2State', { modulo:this.stName, prop:'no_getRecordsAPI', value:2});
        
        // configuro Entorno
        this.Entorno.grid.autoload= true;
        this.Entorno.header.header= false;
        
        this.headers = {
          header:[
             { text: "Ver", value: "acciones", sortable: false, width: "auto", slot:true },        
            {text:  "B", value: "bloqueada", slot:true, style:"color:red"},
            // junto con bloqueada stlot {text:  "A", value: "aceptada" , slot: true},
            { text: "T", value: "tipo_ope", style:"color:steelblue;font-weight:bold"},
            { text: "SUP", value: "suplido", slot:true},
            { text: "FACTURA", value: "fra_doc"},
            { text: "IMPORTE", value: "imp", style:"text-align:right", filtro:"num", arg:2 },
            //{ text: "Pag", value: "pagada", slot:true},
            { text: "P", value: "xpago", style:"color:green;font-weight:bold"},
            { text: "CUENTA", value: "name_cta_id" },            
            { text: "DESCRIPCIÓN", value: "descrip" },           
          ]
        };

        this.Entorno.grid.headers = this.set_headers();  
        
        // compruebo si lleva mto por el tipo de componente
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'exp_pres_M';
      },



      async fusion(tipo_ope, suplido, fusion, funerariaN=0){
         
          //cab almacena valores de cabecera para pase
          //let cab={tipo_ope:tipo_ope, suplido:0/1,  tipo_cta:'0', cta_id:'0',rt:"1",iva_general:"7.0"};
          let cab={tipo_ope:tipo_ope, suplido:suplido, tipo_cta:'0', cta_id:'0', fusion: fusion};
          let lins=[];
          // lins almacena lineas de fusion
         

          // Venta
          if (cab.tipo_ope=="V"){
            cab.tipo_cta=this.miStPadre.record.callemp_tp
            cab.cta_id=this.miStPadre.record.callemp
          }
 
          // compra funeria
          // cuenta funeraria que inicia / funeraria que termina
          if (cab.tipo_ope=="C" && funerariaN>0){
              if (Number(this.miStPadre.record["fune"+funerariaN])=='0'){
                 this.$root.$alert.open("No hay funeraria:"+funerariaN, "error")
                 return
              }
              // cuenta de funeraria
              cab.tipo_cta="1";
              cab.cta_id=this.miStPadre.record["fune"+funerariaN]
  
          }
         // compra otros proveedores no hay que hacer nada
          if (cab.tipo_ope=="C" && funerariaN==0){
             //nada
            cab.tipo_cta="3";            
          }

         // compra suplido
           if (cab.tipo_ope=="C" && cab.suplido==0 && !fusion){
             // Nada
          }

         // Fusion venta
         if (cab.tipo_ope=="V" && fusion && this.selectedItems.filter(item=>item.tipo_ope!="C" || (item.tipo_ope=="C" && item.aceptada==2)).length>0){
             this.$root.$alert.open("Ha seleccionado algún presupuesto  no aceptable", "error")
             return
         }
          // Fusion compra
         if (cab.tipo_ope=="C" && fusion && this.selectedItems.filter(item=>item.tipo_ope!="C" || (item.tipo_ope=="C" && item.aceptada==2)).length>0){
             this.$root.$alert.open("Ha seleccionado algún presupuesto no aceptable", "error")
             return
         }
         // Fusion suplidos
          if (cab.tipo_ope=="C" && cab.suplido==1 && fusion && this.selectedItems.filter(item=>item.tipo_ope!="C" || item.suplido!=1).length>0){
              this.$root.$alert.open("Ha seleccionado algun presupuesto que no es suplido", "error")
              return
          }

          
     
  
       // Si hay fusion pasar las lineas
      if (fusion){
        lins= await this.getLineasFusion();
        if (lins.length==0) return;        
      }

     // compongo datos 
     this.datos_nuevo_presupuesto={cab,lins}
     console.log("datos_nuevo_presupuesto",this.datos_nuevo_presupuesto,lins)

     // llama a NUEVO 
     this.event_btra_Top({accion:1})

    },
 
    async getLineasFusion(){
      if (this.selectedItems.length==0){
          this.$root.$alert.open("No ha seleccionado ninguna factura para fusionar", "error")
          return [];
      }
      let lins=this.selectedItems.map(item=>item.id);
      console.log("cabeceras ids:",lins)
      //pendiente: no da error con accion erronea en sql 
      let args={ 
              tipo:"fnc",
              accion: "presupuestos",
              fn_args:{accion:"fusionLineas",tipo:"fras",id:lins}
             };
      var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
      // pendiente: controlar error
      console.log("*fusion apiResult:",apiResult);
      lins=apiResult.r[0];
    
      if (!Array.isArray(lins)) lins=[];
      return lins;
    },
    
   async refresco(){
    let args={ 
                tipo:"fnc",
                accion: "expediente",
                fn_args:{accion:"get",
                        id:this.miStPadre.record.id,
                        aux:2
                }
    
              };

    console.log("generar args:", args)
    let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
    //pendiente: controlar error
    console.log("generar apiResult:",apiResult.r[0])
    //
    if (apiResult.sql.error==true){
        return
    }
    this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
    //  this.$store.commit('data2State', { modulo:this.stName, prop:'no_getRecordsAPI', value:2});
    // this.$store.commit(this.$store.state[this.stName] + "/data2State", {prop:"records", value:apiResult.r[0]});
    
      },
    
    },
  computed: {
    cerrada(){
      if (this.padre){
         let record=this.$store.state[this.padre].record
         
         if (this.app==2 && (record.chkconf24==1 || record.chkpresapd==1))  return true
         if (record.chkconfadmon==1) return true
         if (record.chkconfcierre==1) return true
      }
      return false
     
    },
    }
  };
</script>
